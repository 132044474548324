var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Bağımsız Bölüm Tipi","icon":"mdi-cube","add-route":_vm.can('edit-assessment')
              ? { name: 'assessments.house-type.create' }
              : null,"hide-edit":!_vm.can('edit-assessment'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":_vm.can('delete-assessment'),"delete-enabled":_vm.deleteEnabled,"delete-tooltip":"Sadece borçlandırma yapılmamış tahakkukları silebilirsiniz"},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":_vm.handleDeleteClick}},'rs-table-title-bar',_vm.titleBarAttrs,false),[_c('template',{slot:"buttons:prepend"})],2):_vm._e(),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDeleteSubmitClick,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'assessments.house-type.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.income_type",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'assessments.house-type.show',
            params: { id: item.id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.issues_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.last_payment_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.total_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.total_collecting_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.remaining_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value,"colored":"","reverse":""}})]}},{key:"item.amount_per_house",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.total_deferment",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.remaining_deferment",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.enforcement_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.enforcement_collecting_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.enforcement_remaining_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.applied",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.blocks",fn:function({ value }){return [_vm._v(" "+_vm._s(value || "Tümü")+" ")]}}],null,false,2702551646),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }